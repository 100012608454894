import React from 'react';
import {useSelector} from 'react-redux';
import {getStatusStyles} from '../../../helpers/getStatusStyles';
import SecondaryButton from '../../SecondaryButton';

function DocumentCard({
  status,
  setOpen,
  documentName,
  setDocumentUrl,
  verificationLink,
  setVerificationLink,
}) {
  const {user} = useSelector(state => state?.auth);

  return (
    <div className="bg-white pb-10 px-8 pt-5 rounded w-full  ">
      <p className="text-grey font-medium mb-10 ">{documentName}</p>
      <div className="flex items-center justify-between border-b border-graphGrey pb-3">
        <div
          className={`px-2 pt-[5px] pb-[3px] text-xs font-medium uppercase rounded-full text-white ${
            getStatusStyles(status).styles
          } w-fit`}
        >
          {status || 'not started'}
        </div>
        <SecondaryButton
          buttonText="Change status"
          className="font-medium p-2 h-8 flex items-center text-[12px]"
          onClick={setOpen}
          disabled={
            user?.role?.includes('Retention') && !user?.role?.includes('Admin')
          }
        />
      </div>

      {documentName.includes('SLA') && (
        <div className="mt-6 flex flex-col ">
          <p className="text-grey60 font-medium mb-3 ">SLA Document link</p>
          <input
            type="text"
            placeholder="Enter SLA Document link here..."
            className="w-full py-4 pl-4 mt-1 input bg-grey20 max-w-full"
            value={verificationLink}
            onChange={e => setDocumentUrl(e.target.value)}
          />
        </div>
      )}

      {!documentName.includes('SLA') && (
        <div className="mt-6 flex flex-col ">
          <p className="text-grey60 font-medium mb-3 ">Verification link</p>
          <input
            type="text"
            placeholder="Enter verification link here..."
            className="w-full py-4 pl-4 mt-1 input bg-grey20 max-w-full"
            value={verificationLink}
            onChange={e => setVerificationLink(e.target.value)}
          />
        </div>
      )}
    </div>
  );
}

export {DocumentCard};
