import React, {useState} from 'react';
import moment from 'moment';
import {useQuery} from 'react-query';
import {
  apiLogs,
  approved,
  check_export,
  failed,
} from '../../assets/images/images';
import DashboardLayout from '../../components/DashboardLayout';
import PrimaryButton from '../../components/PrimaryButton';
import TableLayout from '../../components/TableLayout';
import LayoutContainer from '../../components/LayoutContainer';
import Pagination from '../../components/Pagination';
import DateRangeFilter from '../../components/DateFilter';
import {
  exportLogs,
  fetchApiLogs,
  fetchApiLogsSummary,
} from '../../helpers/queryFns/apiLogs';
import Loader from '../../components/Loader';
import {SearchInput} from '../../components/SearchInput';
import {EmptyState} from '../../components/EmptyState';
import Modal from '../../components/Modal';

export default function ApiLogs() {
  const [env] = useState('Production');
  const [currentPage, setCurrentPage] = useState('1');
  const [searchText, setSearchText] = useState('');
  const [{start, end}, setDateFilters] = useState({});
  const [open, setOpen] = useState(false);

  const {data: summaryData} = useQuery(['logs-summary', {env}], () =>
    fetchApiLogsSummary({env}),
  );

  const {
    data: logsData,
    isLoading,
    isFetching,
  } = useQuery(
    [
      'api-logs',
      {
        end,
        filter: searchText,
        page: currentPage,
        start,
      },
    ],
    () =>
      fetchApiLogs({
        end,
        filter: searchText,
        page: currentPage,
        start,
      }),
  );

  const {
    isLoading: loadingLogsExport,
    isFetching: fetchingLogsExport,
    refetch: refetchingLogsExport,
  } = useQuery('export-logs', () => exportLogs(), {
    enabled: false,
    onSuccess: () => {
      setOpen(false);
    },
  });

  const refreshPage = () => {
    setCurrentPage(1);
    setSearchText('');
    setDateFilters({});
  };

  const pageClick = selected => {
    setCurrentPage(selected);
  };

  return (
    <DashboardLayout headerText="API logs">
      <LayoutContainer>
        <div className="flex flex-wrap gap-8">
          <div className="flex items-center gap-8 py-4 pr-10 rounded-lg grow pl-7 w-fit bg-lighterBlue lg:grow-0">
            <div className="p-[13px] bg-brandBlue rounded-2xl">
              <img src={apiLogs} alt="" />
            </div>
            <div className="flex flex-col">
              <p className="font-medium text-tiny text-grey40">
                Total API logs
              </p>
              <h2 className="text-xl font-bold text-grey100">
                {summaryData?.total?.toLocaleString('en-US') || 0}
              </h2>
            </div>
          </div>
          <div className="flex items-center gap-8 py-4 pr-16 rounded-lg grow pl-7 w-fit bg-lighterBlue lg:grow-0">
            <div className="p-[13px] bg-success rounded-2xl">
              <img src={approved} alt="" />
            </div>
            <div className="flex flex-col">
              <p className="font-medium text-tiny text-grey40">Successful</p>
              <h2 className="text-xl font-bold text-grey100">
                {summaryData?.successful?.toLocaleString('en-US') || 0}
              </h2>
            </div>
          </div>
          <div className="flex items-center gap-8 py-4 pr-16 rounded-lg grow pl-7 w-fit bg-lighterBlue sm:grow-0">
            <div className="p-[13px] bg-danger rounded-2xl">
              <img src={failed} alt="" />
            </div>
            <div className="flex flex-col">
              <p className="font-medium text-tiny text-grey40">Failed</p>
              <h2 className="text-xl font-bold text-grey100">
                {summaryData?.failed?.toLocaleString('en-US') || 0}
              </h2>
            </div>
          </div>
        </div>

        <div>
          <h4 className="mt-12 mb-4 font-medium text-grey60">API logs</h4>
          <div className="flex flex-wrap justify-between gap-4">
            <SearchInput
              placeholder="Search logs"
              onChange={text => setSearchText(text)}
            />
            <DateRangeFilter onChange={date => setDateFilters(date)} />
            <div className="ml-0 lg:ml-auto">
              {!isLoading && logsData?.api_logs?.length ? (
                <PrimaryButton
                  buttonText="Export table"
                  xPadding="px-6"
                  className="font-medium"
                  onClick={() => setOpen(!open)}
                />
              ) : (
                <PrimaryButton
                  buttonText="Refresh"
                  xPadding="px-6"
                  className="font-medium"
                  onClick={refreshPage}
                />
              )}
              <Modal show={open} onClose={setOpen}>
                <div className="flex flex-col items-center px-12 pt-20 pb-10">
                  <img className="w-[134px]" src={check_export} alt="" />
                  <h2 className="mt-4 mb-6 text-lg font-bold text-center text-grey60">
                    Your export file is being prepared. When it is ready, it
                    will be sent to the email attached to this account.
                  </h2>
                  <PrimaryButton
                    buttonText="Continue"
                    xPadding="px-6"
                    className="w-full font-medium"
                    loadingState={loadingLogsExport || fetchingLogsExport}
                    disabled={loadingLogsExport || fetchingLogsExport}
                    onClick={refetchingLogsExport}
                  />
                  <button
                    type="button"
                    className="inline-flex justify-center w-full mt-6 text-navGrey"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Modal>
            </div>
          </div>
        </div>

        <div className="mt-3">
          {isLoading ? (
            <Loader height={45} />
          ) : (
            <>
              {!logsData?.totalRecords ? (
                <EmptyState
                  height={45}
                  title="No log listed"
                  body="No log is listed yet"
                />
              ) : (
                <TableLayout>
                  <thead className="text-xs font-semibold uppercase">
                    <tr className="border-b border-tableGrey">
                      <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                        product
                      </th>
                      <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                        app name
                      </th>
                      <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                        services
                      </th>
                      <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                        cost
                      </th>
                      <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                        company
                      </th>
                      <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                        type
                      </th>
                      <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                        environment
                      </th>
                      <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                        created
                      </th>
                      <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                        status
                      </th>
                      <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                        status code
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    className="text-sm divide-y divide-gray-100"
                    style={{opacity: isFetching && 0.3}}
                  >
                    {logsData?.api_logs?.map((log, i) => (
                      <tr className="border-b border-tableGrey" key={i}>
                        <td className="p-3 text-sm text-grey80 whitespace-nowrap">
                          {log?.product || '-'}
                        </td>
                        <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                          {log?.app?.name || '-'}
                        </td>
                        <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                          {log?.service || '-'}
                        </td>
                        <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                          {new Intl.NumberFormat('en-NG', {
                            currency: 'NGN',
                            style: 'currency',
                          }).format(log?.cost / 100 || 0)}
                        </td>
                        <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                          {log?.company?.name || '-'}
                        </td>
                        <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                          {log?.type || '-'}
                        </td>
                        <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                          {log?.env || '-'}
                        </td>
                        <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                          {moment(log?.createdAt).format('Do MMM, YYYY') || '-'}
                        </td>
                        <td
                          className={`p-3 text-left text-tiny text-${
                            log?.status === 'Success' ? 'success' : 'danger'
                          } whitespace-nowrap`}
                        >
                          {log?.status || '-'}
                        </td>
                        <td
                          className={`p-3 text-left text-tiny text-${
                            log?.status_code === '200' ? 'success' : 'danger'
                          } whitespace-nowrap`}
                        >
                          {log?.status_code || '-'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </TableLayout>
              )}
            </>
          )}

          <div className="flex flex-wrap items-baseline justify-center gap-4 mb-14 sm:justify-between">
            <Pagination
              total={(logsData && logsData?.totalPages) || 0}
              current={+currentPage}
              onPageChange={activePage => {
                pageClick(activePage);
              }}
            />
          </div>
        </div>
      </LayoutContainer>
    </DashboardLayout>
  );
}
